import React from 'react';

import Footer from "./Footer";
import imgLogo from "../../assets/img/logo.png";

export default function EmptyLayout(props) {
    return (
        <div className="position-relative overflow-hidden min-vh-100 d-flex flex-column align-items-center justify-content-center">
            <div className="pb-5 text-center">
                <img src={imgLogo} height="48" alt=""/>
            </div>

            {props.children}

            <Footer/>
        </div>
    );
}
