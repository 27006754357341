import BaseCrudModel from "./BaseCrudModel";

/**
 * Projects model
 *
 * @param {ApiHelper} api
 */
export default class ProjectsModel extends BaseCrudModel {
    baseUrl = '/projects';

    constructor(api) {
        super(api);
    }


    async sync(projectId, errorHandler) {
        const url = this.baseUrl + '/' + projectId + '/sync';
        const response = await this.api.postRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async export(projectId, errorHandler) {
        const url = this.baseUrl + '/' + projectId + '/export';
        const response = await this.api.postRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async syncCampaigns(projectId, errorHandler) {
        const url = this.baseUrl + '/' + projectId + '/sync-campaigns';
        const response = await this.api.postRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async exchangeFacebookCode(code, errorHandler) {
        const response = await this.api.exchangeFacebookCode(code);

        return this.processResponse(response, errorHandler);
    }


    async fetchPages(access_token, errorHandler) {
        const url = this.baseUrl + '/fetch-pages?access_token=' + access_token;
        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async list(filter = null, sorting =  null, archived = null, errorHandler) {
        let url = this.baseUrl;
        let query = {};
        if (filter) {
            query.filter = filter;
        }
        if (sorting) {
            query.sort = sorting.field;
            query.direction = sorting.direction;
        }
        if (archived !== null) {
            query.archived = archived;
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }

        const response = await this.api.getRequest(url);
        return this.processResponse(response, errorHandler);
    }


    async requestReport(projectId, data, errorHandler) {
        const url = this.baseUrl + '/' + projectId + '/request-report';

        const response = await this.api.postRequest(url, data);

        return this.processResponse(response, errorHandler);
    }


    async copy(projectId, errorHandler) {
        const url = this.baseUrl + '/' + projectId + '/copy';

        const response = await this.api.postRequest(url);

        return this.processResponse(response, errorHandler);
    }
}