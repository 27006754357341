import React, {useEffect} from 'react';

import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useApi} from "../../providers/ApiProvider";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {Link, useParams} from "react-router-dom";
import {useNotifications} from "../../providers/NotificationsProvider";
import ProjectsForm from "./ProjectsForm";
import ProjectsModel from "../../models/ProjectsModel";
import {useMeta} from "../../providers/MetaProvider";

export default function ProjectsEdit(props) {
    const params = useParams();
    const breadcrumbs = useBreadcrumbs();
    const metaContext = useMeta();
    const apiContext = useApi();
    const notificationsContext = useNotifications();
    const projectsModel = new ProjectsModel(apiContext.api);

    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState({});
    const [formData, setFormData] = React.useState({});
    const [title, setTitle] = React.useState('');


    const buildBreadcrumbs = () => {
        if (!formData) {
            return [];
        }

        let breadcrumbs = [
            {label: 'My Projects', url: '/p'}
        ];

        if (formData.title) {
            breadcrumbs.push({label: formData.title, url: '/p/'+formData.id});
        }

        return breadcrumbs;
    }


    useEffect(() => {
        if (formData && formData.title) {
            breadcrumbs.update({
                title: formData.title,
                breadcrumbs: buildBreadcrumbs()
            });
        }

        metaContext.update({
            title: title,
            description: 'Edit project'
        });


        loadData().finally(() => {
            setLoading(false);
        });
    }, []);


    useEffect(() => {
        if (formData && formData.title) {
            breadcrumbs.update({
                title: formData.title,
                breadcrumbs: buildBreadcrumbs()
            });
        }

        metaContext.update({
            title: title,
            description: 'Edit project'
        });
    }, [title]);


    const loadData = () => {
        return projectsModel.view(params.projectId).then((response) => {
            if (response !== false) {
                setFormData(response);

                if (response.title) {
                    let text = response.title;
                    setTitle(text);
                }
            }
        });
    }


    const onSubmit = (data) => {
        setLoading(true);

        projectsModel.update(params.projectId, data, onUpdateError).then((response) => {
            if (response !== false) {
                setLoading(false);
                setErrors({});
                setFormData(response);

                notificationsContext.notify('Project updated!', 'success');
            }
        }).catch((response) => {
            onUpdateError(response);
        });
    }


    const onUpdateError = (response) => {
        if (response && response.data) {
            setErrors(response.data);
        }

        setLoading(false);
        notificationsContext.notify('Couldn\'t update project', 'error');
    }


    return (<div className="row">
        <div className="col-lg-6">
            <SimpleCardContainer title={title} loading={loading}>
                <ProjectsForm
                    formData={formData}
                    className={'py-2'}
                    errors={errors}
                    isNewRecord={false}
                    onSubmit={onSubmit}
                />
            </SimpleCardContainer>
        </div>
    </div>);
}
