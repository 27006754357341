import React, {useEffect} from "react";

function Alert({type, text}) {

    const [show, setShow] = React.useState(false);
    const [alertType, setAlertType] = React.useState(type);


    useEffect(() => {
        const timerId = setTimeout(() => {
            setShow(false);

            setTimeout(() => {
                setAlertType(null);
            }, 250);
        }, 3000);
    }, [show]);


    useEffect(() => {
        setTimeout(() => {
            setShow(true);
        }, 50);
    }, []);


    return alertType && (
        <div className={'alert alert-'+alertType+' fade'+(show ? ' show' : '')} role="alert">
            {text}
        </div>
    );
}

export default Alert;