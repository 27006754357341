import {createBrowserRouter, Outlet} from "react-router-dom";
import App from "./App";
import {RequireAuth} from "./providers/AuthProvider";
import Home from "./components/common/Home";
import Auth from "./components/auth/Auth";
import Login from "./components/auth/Login";
import React from "react";
import ForgotPassword from "./components/auth/ForgotPassword";
import Error404 from "./components/errors/Error404";
import Logout from "./components/auth/Logout";
import MainLayout from "./components/layout/MainLayout";
import Faq from "./components/common/Faq";
import ForgotPasswordConfirm from "./components/auth/ForgotPasswordConfirm";
import Register from "./components/auth/Register";
import FacebookCallback from "./components/auth/FacebookCallback";
import AccountSettings from "./components/users/AccountSettings";
import Projects from "./components/projects/Projects";
import ProjectCreate from "./components/projects/create/ProjectCreate";
import ProjectView from "./components/projects/ProjectView";
import ProjectsList from "./components/projects/ProjectsList";
import CampaignsList from "./components/campaigns/CampaignsList";
import AudiencesList from "./components/projects/audiences/AudiencesList";
import SimpleLayout from "./components/layout/SimpleLayout";
import Questionnaire from "./components/questionnaire/Questionnaire";
import EmptyLayout from "./components/layout/EmptyLayout";
import ProjectCreateAccount from "./components/projects/create/ProjectCreateAccount";
import ProjectCreateQuestionnaire from "./components/projects/create/ProjectCreateQuestionnaire";
import CampaignsSuggested from "./components/campaigns/CampaignsSuggested";
import ProjectsEdit from "./components/projects/ProjectsEdit";

export const router = createBrowserRouter([
    {
        path: "/",
        Component: App,
        children: [
            {
                index: true,
                element: (<RequireAuth><MainLayout><Home/></MainLayout></RequireAuth>),
            },
            {
                path: "auth",
                Component: Auth,
                children: [
                    {
                        path: "login",
                        Component: Login,
                    },
                    {
                        path: "facebook/callback",
                        Component: FacebookCallback,
                    },
                    {
                        path: "register",
                        Component: Register,
                    },
                    {
                        path: "forgot-password",
                        children: [
                            {
                                path: "confirm",
                                Component: ForgotPasswordConfirm,
                            },
                            {
                                index: true,
                                Component: ForgotPassword,
                            }
                        ],
                    },
                    {
                        path: "logout",
                        Component: Logout,
                    },
                ],
            },
            {
                path: "p",
                element: (<RequireAuth><MainLayout><Projects /></MainLayout></RequireAuth>),
                children: [
                    {
                        path: "new",
                        element: (<Outlet />),
                        children: [
                            {
                                path: "account",
                                Component: ProjectCreateAccount
                            },
                            {
                                index: true,
                                Component: ProjectCreate
                            },
                        ]
                    },
                    {
                        path: ":projectId/intro",
                        Component: ProjectCreateQuestionnaire
                    },
                    {
                        path: ":projectId/suggested",
                        Component: CampaignsSuggested
                    },
                    {
                        path: ":projectId/campaigns",
                        Component: CampaignsList
                    },
                    {
                        path: ":projectId/audiences",
                        Component: AudiencesList
                    },
                    {
                        path: ":projectId/edit",
                        Component: ProjectsEdit
                    },
                    {
                        path: ":projectId",
                        Component: ProjectView
                    },
                    {
                        index: true,
                        Component: ProjectsList
                    }
                ],
            },
            {
                path: "intro",
                element: (<RequireAuth><EmptyLayout><Questionnaire /></EmptyLayout></RequireAuth>),
            },
            {
                path: "settings",
                element: (<RequireAuth><MainLayout><AccountSettings /></MainLayout></RequireAuth>),
            },
            {
                path: "faq",
                element: (<RequireAuth><MainLayout><Faq /></MainLayout></RequireAuth>),
            },
        ],
    },
    {
        path: "*",
        Component: Error404,
    }
]);
