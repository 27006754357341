import React, {useEffect} from 'react';

import {useApi} from "../../../providers/ApiProvider";
import {Navigate, useParams} from "react-router-dom";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsModel from "../../../models/ProjectsModel";
import {useAuth} from "../../../providers/AuthProvider";
import SimpleCardContainer from "../../ui/SimpleCardContainer";
import FacebookAuthContainer from "../FacebookAuthContainer";
import FormTextField from "../../layout/ui/FormTextField";
import FormSelect from "../../layout/ui/FormSelect";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";

export default function ProjectCreateForm({adAccounts = {}, accessToken = '', onSubmitInitialized}) {
    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);
    const notifications = useNotifications();
    const {user} = useAuth();

    const [title, setTitle] = React.useState('');
    const [adAccountId, setAdAccountId] = React.useState('');
    const [adAccountOptions, setAdAccountOptions] = React.useState([]);
    const [errors, setErrors] = React.useState({});
    const [loading, setLoading] = React.useState('');
    const [createdProjectId, setCreatedProjectId] = React.useState(null);


    useEffect(() => {
        let newOptions = [];
        Object.keys(adAccounts).forEach((key) => {
            newOptions.push({value: key, label: adAccounts[key].name + ' (' + adAccounts[key].id.replace('act_', '') + ')'});
        });

        setAdAccountOptions(newOptions);

        if (newOptions.length === 1) {
            setAdAccountId(newOptions[0].value);
            setTitle(adAccounts[newOptions[0].value].name);
        }
    }, [adAccounts]);


    const onSubmit = (e) => {
        e.preventDefault();
        setLoading('submitted');
        onSubmitInitialized();

        const data = {
            title: title,
            adaccount_id: adAccountId,
            params: {
                access_token: accessToken
            }
        };
        projectsModel.create(data, uploadErrorHandler).then((response) => {
            if (response && response.id) {
                //notifications.notify('Project created!', 'success');
                setCreatedProjectId(response.id);

                projectsModel.sync(response.id);
            } else {
                uploadErrorHandler(response);
            }
        }).catch((error) => {
            uploadErrorHandler(error.response);
        });
    }


    const uploadErrorHandler = (response) => {
        if (response && (response.success === false)) {
            setErrors(response.data);
            notifications.notify('Error appeared!', 'error');
        }
    }


    const adAccountChanged = (value) => {
        setAdAccountId(value);

        let selectedAdAccount = adAccounts[value];
        setTitle(selectedAdAccount.name);
    }


    return createdProjectId ? (<Navigate to={'/p/'+createdProjectId+'/intro'} />) : (<div >
        {adAccountOptions.length > 1 ? (
            <FormSelect
                placeholder={'Select an Ad Account...'}
                groupClassName={'mb-3'}
                label={'Ad Account'}
                name={'ad_account_id'}
                errors={errors.ad_account_id}
                value={adAccountId}
                options={adAccountOptions}
                onChange={adAccountChanged}
                required={true}
            />
        ) : (
            <FormTextField
                groupClassName={'mb-3'}
                label={'Ad Account'}
                name={'ad_account_id'}
                errors={errors.ad_account_id}
                value={adAccountId}
                onChange={(e) => setAdAccountId(e.target.value)}
                disabled={true}
            />
        )}

        <FormTextField
            groupClassName={'mb-3'}
            label={'Title'}
            name={'title'}
            errors={errors.title}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required={true}
        />

        <ButtonWithLoading
            className={"btn-primary"}
            label={'Save and Continue'}
            onClick={onSubmit}
            loading={loading === 'submitted'}
            noOriginalIconClassName={true}
            iconClassName={'ti ti-plus fs-4 me-2'}
        />

    </div>);
}
