import React, {useState, useCallback} from "react";

import SimpleBar from "simplebar-react";
import {useAuth} from "../../../providers/AuthProvider";

import imgProfileTmp from 'modernize-essentials/dist/images/profile/user-1.jpg';
import {Link} from "react-router-dom";


function HeaderProfile(props) {
    const authContext = useAuth();
    const userData = authContext.user;

    let imgProfile = imgProfileTmp;
    if (userData.avatar) {
        imgProfile = userData.avatar;
    }


    const getLabelByRole = (role) => {
        let label = '-';
        switch (role) {
            case 'admin': label = 'Administrator'; break;
        }

        return label;
    }


    return (<>
        <a className="nav-link" href="#" id="drop1" data-bs-toggle="dropdown" aria-expanded="false">
            <div className="d-flex align-items-center">
                <div className="user-profile-img d-flex">
                    <img src={imgProfile} className="rounded-circle object-fit-cover" width="35" height="35" alt=""/>
                </div>
            </div>
        </a>
        <div className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop1">
            <SimpleBar autoHide={true} className={'profile-dropdown position-relative'}>
                <div className="d-flex align-items-center py-9 mx-7">
                    <img src={imgProfile} className="rounded-circle object-fit-cover" width="80" height="80" alt=""/>
                    <div className="ms-3">
                        <h5 className="d-flex align-items-center fs-4 mb-0">
                            {userData.name}
                            <Link to={'/settings'} reloadDocument className="ms-3 mt-1">
                                <i className={'ti ti-settings fs-5'}></i>
                            </Link>
                        </h5>
                        {userData.role !== 'user' && (<span className="mb-0 d-block text-dark">{getLabelByRole(userData.role)}</span>)}
                        <p className="mb-0 d-flex text-dark align-items-center">
                            {userData.email}
                        </p>
                    </div>
                </div>
                <div className="d-flex justify-content-end py-4 px-7 pt-8">
                    <a href="/auth/logout" className="btn btn-outline-dark d-flex align-items-center">
                        Sign out
                        <i className="ti ti-logout fs-4 ms-2"></i>
                    </a>
                </div>
            </SimpleBar>
        </div>
    </>)
}

export default HeaderProfile;