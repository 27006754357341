import React, {useEffect} from 'react';

import {useApi} from "../../../providers/ApiProvider";
import {Link, useParams} from "react-router-dom";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsModel from "../../../models/ProjectsModel";
import {useAuth} from "../../../providers/AuthProvider";
import SimpleCardContainer from "../../ui/SimpleCardContainer";
import FacebookAuthContainer from "../FacebookAuthContainer";
import ProjectCreateForm from "./ProjectCreateForm";
import Questionnaire from "../../questionnaire/Questionnaire";

export default function ProjectCreateQuestionnaire({}) {
    const {projectId} = useParams();
    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);
    const notifications = useNotifications();
    const {user} = useAuth();

    const [project, setProject] = React.useState({});
    const [accessToken, setAccessToken] = React.useState('');
    const [loading, setLoading] = React.useState([]);
    const [progress, setProgress] = React.useState(0.3);


    useEffect(() => {
        getProjectInfo();
    }, []);


    const getProjectInfo = () => {
        if (!loading.includes('general')) {
            setLoading([...loading, 'general']);
        }

        projectsModel.view(projectId, uploadErrorHandler).then((response) => {
            if (response !== false) {
                setProject(response);
            }
        }).finally(() => {
            setLoading(loading.filter((item) => item !== 'general'));
        });
    }


    const uploadErrorHandler = (response) => {
        if (response && response.error) {
            notifications.notify('Loading error!', 'error');
        }
    }


    const onSubmit = (info, techData) => {
        projectsModel.update(projectId, {info: info, params: {...project.params, tech: techData}}, uploadErrorHandler).then((response) => {
            if (response !== false) {
                //notifications.notify('Project updated!', 'success');
                setProgress(1);

                window.location.href = '/p/' + projectId;
            }
        });
    }


    const pushProgress = () => {
        let newProgress = progress + 0.1;
        if (newProgress > 1) {
            newProgress = 1;
        }

        setProgress(newProgress);
    }


    return (<div className="d-flex flex-column align-items-center">
        <div className="align-items-start d-flex flex-column" style={{maxWidth: '500px'}}>
            <Link to="/p/new" className="btn btn-light-primary text-primary d-flex align-items-center mb-3 w-auto"><i className="ti ti-arrow-left fs-4 me-2"></i>Select another asset</Link>

            <SimpleCardContainer
                title={'Information about the project'}
                loading={loading.includes('submitted')}
                progress={progress}
            >
                <Questionnaire
                    onProgress={pushProgress}
                    onSubmit={onSubmit}
                />
            </SimpleCardContainer>
        </div>
    </div>);
}
