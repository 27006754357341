import React, {useEffect, useRef} from 'react';

import {useNotifications} from "../../../providers/NotificationsProvider";
import {useApi} from "../../../providers/ApiProvider";
import {Link, useParams} from "react-router-dom";
import CampaignsModel from "../../../models/CampaignsModel";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import Grades from "../../../helpers/Grades";
import {useMeta} from "../../../providers/MetaProvider";
import ProjectsModel from "../../../models/ProjectsModel";

export default function AudiencesList({}) {
    const metaContext = useMeta();
    const params = useParams();
    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);
    const campaignsModel = new CampaignsModel(apiContext.api, params.projectId);
    const notifications = useNotifications();

    const [project, setProject] = React.useState({});
    const [audiences, setAudiences] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const [data, setData] = React.useState([]);
    const [filter, setFilter] = React.useState({
        searchText: null,
        sorting: {field: 'synced_at', direction: 'desc'},
        active: 0
    });
    const [loadingCampaigns, setLoadingCampaigns] = React.useState([]);


    const loadData = () => {
        setLoading(true);

        projectsModel.view(params.projectId, uploadErrorHandler).then((response) => {
            if (response !== false) {
                setProject(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const uploadErrorHandler = (response) => {
        if (response.data && response.data[0].error) {
            notifications.notify('Loading error', 'error');
        }
    }


    useEffect(() => {
        loadData();
    }, []);


    useEffect(() => {
        setAudiences(project.audiences ? project.audiences : []);
    }, [project]);


    return (
        <>
            <div className="d-flex flex-row justify-content-start m-block-gap">
                <Link to={'/p/'+params.projectId} className="btn btn-light-primary text-primary">Back to the project</Link>
            </div>


            {(audiences && audiences.length || loading) ? (
                <div className="table-responsive rounded-2">
                    <table className="table border customize-table mb-0 align-middle">
                        <thead className="text-dark fs-4">
                        <tr>
                            <th>
                                <h6 className={'fs-4 fw-semibold mb-0'}>
                                    Audience
                                </h6>
                            </th>
                            <th style={{width: '300px'}}>
                                <h6 className={'fs-4 fw-semibold mb-0'}>
                                    Delivery
                                </h6>
                            </th>
                            <th style={{width: '160px'}}><h6 className="fs-4 fw-semibold mb-0"></h6></th>
                        </tr>
                        </thead>
                        <tbody>
                        {audiences && audiences.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <div className="d-flex gap-1 flex-column">
                                            <Link to={'/p/' + params.projectId + '/audiences/' + item.id} className="d-block">{item.name}</Link>
                                            <div className="text-muted fs-3">{item.subtype}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex gap-1 flex-column">
                                            {item.delivery_status ? item.delivery_status.description : JSON.stringify(item.delivery_status)}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-end gap-3">
                                            <button className="btn btn-circle btn-light-warning text-warning" onClick={() => {

                                            }}>
                                                <i className="ti ti-archive fs-4"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="text-center fs-2 mt-4">
                    No audiences found.
                </div>
            )}
        </>
    );
}