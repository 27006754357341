import React from 'react';
import packageJson from '../../../package.json';


function Footer(props) {


    return (
        <footer className="footer">
            <div className="row g-0 justify-content-between fs--1 mt-4 mb-3">
                <div className="col-12 col-sm-auto text-center">
                    <p className="mb-0 text-600">{(new Date()).getFullYear()} &copy; SpryntAI</p>
                </div>
                <div className="col-12 col-sm-auto text-center">
                    <p className="mb-0 text-600">v{packageJson.version}</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
