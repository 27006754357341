import React from 'react';
import jwtDecode from 'jwt-decode';

import SimpleLayout from "../layout/SimpleLayout";
import {Navigate, Outlet} from "react-router-dom";
import {useAuth, userStructure} from "../../providers/AuthProvider";
import {useApi} from "../../providers/ApiProvider";
import GoogleAuthProcessing from "./GoogleAuthProcessing";
import Error404 from "../errors/Error404";


const Auth = (props) => {
    const authContext = useAuth();
    const apiContext = useApi();

    const [isAuthenticated, setIsAuthenticated] = React.useState('none');

    const onSuccessfulAuth = (response) => {
        const decodedAccessToken = jwtDecode(response.access_token);
        let decodedRefreshToken = null;
        if (response.refresh_token) {
            decodedRefreshToken = jwtDecode(response.refresh_token);
        }

        let user = {...userStructure};
        user.id = decodedAccessToken.user_id;
        user.access_token = response.access_token;
        user.access_token_expires_at = decodedAccessToken.exp;
        user.refresh_token = response.refresh_token;
        user.refresh_token_expires_at = (decodedRefreshToken)?(decodedRefreshToken.exp):null;

        Object.keys(decodedAccessToken).forEach((key) => {
            if (['user_id', 'token'].indexOf(key) === -1) {
                user[key] = decodedAccessToken[key];
            }
        });

        authContext.login(user);

        setIsAuthenticated('authenticated');
    }


    const onFacebookAuth = (facebookAuthCode) => {
        const credentials = {
            facebookAuthCode: facebookAuthCode
        }

        setIsAuthenticated('authenticating');

        apiContext.api.authLoginWithFacebook(credentials).then((response) => {
            if (response === false || !response.data || !response.data.access_token) {
                alert('Wrong credentials.');
            } else {
                onSuccessfulAuth(response.data);
            }
        });
    }


    return (
        <SimpleLayout>
                {
                    isAuthenticated === 'authenticated' && (<Navigate to={'/'} replace={true} />) ||
                    isAuthenticated === 'authenticating' && (<GoogleAuthProcessing />) ||
                    isAuthenticated === 'none' && (<Outlet
                        context={{
                            onSuccessfulAuth: onSuccessfulAuth,
                            onFacebookAuth: onFacebookAuth
                        }}
                    />) ||
                    (<Error404 />)
                }
        </SimpleLayout>
    );
}

export default Auth;
