import React, {useEffect} from 'react';

import {useApi} from "../../providers/ApiProvider";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";

import imgProfileDefault from '../../assets/img/blank.png';
import Alert from "../ui/Alert";
import UsersModel from "../../models/Users/UsersModel";
import {useAuth} from "../../providers/AuthProvider";
import ButtonWithLoading from "../layout/ui/ButtonWithLoading";

export default function ProfilePhotoCard({}) {
    const authContext = useAuth();
    const userData = authContext.user;
    const apiContext = useApi();
    const usersModel = new UsersModel(apiContext.api);
    const [avatar, setAvatar] = React.useState(userData.avatar);

    const [alertNotification, setAlertNotification] = React.useState({type: null, text: null});
    const [loading, setLoading] = React.useState('');


    useEffect(() => {
        fetchAvatar();
    }, []);


    useEffect(() => {
        authContext.refresh({avatar: avatar});
    }, [avatar]);


    const fetchAvatar = () => {
        usersModel.profile().then((response) => {
            if (response !== false && response.avatar !== avatar) {
                setAvatar(response.avatar);
            }
        });
    }


    const onFileLoaded = (e) => {
        usersModel.updateAvatar(e.target.result).then((response) => {
            if (response !== false) {
                setAlertNotification({
                    type: 'success',
                    text: 'Information changed successfully.'
                });

                const versionedAvatar = response.avatar+'?v='+new Date().getTime();
                setAvatar(versionedAvatar);
            } else {
                setAlertNotification({
                    type: 'danger',
                    text: 'Information change failed.'
                });
            }
        }).finally(() => {
            setLoading('');
        });
    }
    const onFileError = () => {
        setAlertNotification({
            type: 'danger',
            text: 'File reading failed.'
        });
        setLoading('');
    }


    const onFileChange = (e) => {
        e.preventDefault();
        setLoading('fileLoading');

        let fileContents = e.target.files[0];
        let reader = new FileReader();
        reader.onload = onFileLoaded;
        reader.onerror = onFileError;
        reader.readAsDataURL(fileContents);
    }

    const triggerFileChange = (e) => {
        e.preventDefault();

        document.querySelector('#avatar-change-file').click();
    }


    const syncWithFacebook = (e) => {
        e.preventDefault();
        setLoading('syncWithFacebook');

        usersModel.syncAvatarWithFacebook().then((response) => {
            if (response !== false) {
                setAlertNotification({
                    type: 'success',
                    text: 'Information changed successfully.'
                });

                setAvatar(response.avatar);
            } else {
                setAlertNotification({
                    type: 'danger',
                    text: 'Information change failed.'
                });
            }
        }).finally(() => {
            setLoading('');
        });
    }


    return (
        <div className="card w-100 position-relative overflow-hidden">
            <div className="card-body p-4">
                <h5 className="card-title fw-semibold">Change Profile</h5>
                <p className="card-subtitle mb-4">Change your profile picture from here.</p>

                {alertNotification.text && (
                    <Alert
                        type={alertNotification.type}
                        text={alertNotification.text}
                    />
                )}

                <div className="text-center">
                    <img src={avatar ? avatar : imgProfileDefault} alt="Profile pic" className="rounded-circle object-fit-cover" width="120" height="120"/>

                    <form>
                        <input type="file" className="d-none" id="avatar-change-file" onChange={onFileChange}/>

                        <div className="d-flex align-items-center justify-content-center mt-4 mb-2 gap-3">
                            <ButtonWithLoading
                                className="btn btn-primary btn-flex justify-content-center"
                                noOriginalIconClassName={true}
                                onClick={triggerFileChange}
                                label="Upload"
                                loading={loading === 'fileLoading'}
                            />
                        </div>
                    </form>

                    <p className="mb-0 fs-2">Allowed JPG, GIF or PNG. Max size of 2 MB.</p>

                    <div className="d-flex align-items-center justify-content-center mt-4 gap-3">
                        <ButtonWithLoading
                            className="btn btn-light-primary text-primary btn-flex justify-content-center"
                            noOriginalIconClassName={true}
                            spinnerClassName="text-primary"
                            onClick={syncWithFacebook}
                            label="Sync with Facebook"
                            loading={loading === 'syncWithFacebook'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
