import React from 'react';

export default function Questionnaire({onProgress, onSubmit}) {
    const [answers, setAnswers] = React.useState({});
    const [techData, setTechData] = React.useState({
        'location': '',
        'website': ''
    });
    const [step, setStep] = React.useState(0);

    const questions = [
        {
            question: 'What is your Brand name?',
            hint: ''
        },
        {
            question: 'What is your website?',
            hint: ''
        },
        {
            question: 'What is the goal of your ads?',
            hint: 'For example, this can be to drive sales, or to increase brand awareness.'
        },
        {
            question: 'What is your monthly budget?',
            hint: 'Please, enter an approximate amount.'
        },
        {
            question: 'Where are your customers located?',
            hint: 'Please, enter one or several locations: country, region, or city.'
        },
        {
            question: 'Describe your potential clients?',
            hint: 'For example, this can be their age, gender, interests, etc.'
        }
    ];


    const onAnswerChange = (e) => {
        let newAnswers = {...answers};
        newAnswers[step] = {...questions[step], answer: e.target.value};

        if (step === 1) {
            setTechData({...techData, 'website': e.target.value});
        }
        if (step === 4) {
            setTechData({...techData, 'location': e.target.value});
        }

        setAnswers(newAnswers);
    }


    const goNextStep = (e) => {
        setStep(step + 1);
        if (step === questions.length - 1) {
            onSubmit(answers, techData);
        }
        onProgress();
    }


    return (
        <div className="text-center">
            <div className="mb-7 text-center text-muted">Please, answer 6 questions about your business to start.</div>
            <div className="d-flex align-items-center justify-content-center w-100">
                <div className="w-100">
                    <div className="">
                        {step < questions.length ? (<>
                        <div className="">
                            <h3 className="fw-semibold fs-6 mb-7">{questions[step].question}</h3>
                            {step === 2 ? (
                                <select className="form-select form-control-lg" onChange={onAnswerChange}>
                                    <option value="Increase ROAS">Increase ROAS</option>
                                    <option value="Generate more conversions">Generate more conversions</option>
                                    <option value="Get more traffic">Get more traffic</option>
                                    <option value="Get engagement">Get engagement</option>
                                </select>
                            ) : ''}
                            {step !== 2 ? (
                                <input type="text" value={answers[step] ? answers[step].answer : ''} onChange={onAnswerChange} className="form-control form-control-lg"/>
                            ) : ''}
                        </div>
                        <div className="mt-3 d-flex justify-content-end">
                            <button className="btn btn-primary" onClick={goNextStep}>Next</button>
                        </div>
                        </>) : (
                            <div className="text-center">
                                <h1 className="fw-semibold mb-7 fs-9">Thank you for your answers!</h1>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}