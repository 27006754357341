
export default class Grades {
    static defaultGrading = {
        10: {color: "#2A3547", className: "dark"},
        50: {color: "#fa896b", className: "danger"},
        85: {color: "#ffae1f", className: "warning"},
        105: {color: "#13DEB9", className: "success"},
        'default': {color: "#5D87FF", className: "primary"}
    };


    /**
     * Get color by grade
     * @param grade
     * @returns {string}
     */
    static getClassNameByGrade = (grade) => {
        const grading = Grades.defaultGrading;

        let className = grading['default'].className;
        for (let key in grading) {
            if (grade < key) {
                className = grading[key].className;
                break;
            }
        }

        return className;
    }



    /**
     * Format date to human readable format
     * @param {int} inputTimestamp
     * @param {boolean} addTime
     * @returns {string}
     */
    static getDateFormatted = (inputTimestamp, addTime = true) => {
        if (!inputTimestamp) {
            return '';
        }

        const date = new Date(inputTimestamp * 1000);
        const today = new Date();
        let dateOptions = {day: 'numeric', month: 'long', year: 'numeric'};

        if (date.getFullYear() === today.getFullYear()) {
            dateOptions = {day: 'numeric', month: 'long'};
        }

        if (addTime) {
            dateOptions.hour = 'numeric';
            dateOptions.minute = 'numeric';
        }

        return date.toLocaleDateString('en-US', dateOptions);
    }
}