import React, {useEffect, useRef} from 'react';

import {useNotifications} from "../../providers/NotificationsProvider";
import {useApi} from "../../providers/ApiProvider";
import {Link, useParams} from "react-router-dom";
import CampaignsModel from "../../models/CampaignsModel";
import ButtonWithLoading from "../layout/ui/ButtonWithLoading";
import Grades from "../../helpers/Grades";
import {useMeta} from "../../providers/MetaProvider";
import ProjectsModel from "../../models/ProjectsModel";

export default function CampaignsList({}) {
    const metaContext = useMeta();
    const params = useParams();
    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);
    const campaignsModel = new CampaignsModel(apiContext.api, params.projectId);
    const notifications = useNotifications();

    const [project, setProject] = React.useState({});
    const [campaigns, setCampaigns] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const [data, setData] = React.useState([]);
    const [filter, setFilter] = React.useState({
        searchText: null,
        sorting: {field: 'synced_at', direction: 'desc'},
        active: 0
    });
    const [loadingCampaigns, setLoadingCampaigns] = React.useState([]);


    const loadData = () => {
        setLoading(true);

        campaignsModel.list(filter.searchText, filter.sorting, filter.active, uploadErrorHandler).then((response) => {
            if (response !== false) {
                setData(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const uploadErrorHandler = (response) => {
        if (response.data && response.data[0].error) {
            notifications.notify('Loading error', 'error');
        }
    }


    useEffect(() => {
        loadData();
    }, []);


    useEffect(() => {
        setData(campaigns);
    }, [campaigns]);


    const onActiveToggleClick = (campaign) => {
        campaignsModel.update(campaign.id, {is_active:  campaign.is_active ? 0 : 1}, uploadErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('Campaign updated', 'success');
            }
        }).finally(() => {
            loadData();
        });
    }


    const onCampaignRefresh = (campaign) => {
        if (!loadingCampaigns.includes(campaign.id)) {
            setLoadingCampaigns([...loadingCampaigns, campaign.id]);
        }

        campaignsModel.refresh(campaign.id, uploadErrorHandler).then((response) => {
            if (response !== false) {
                let newData = [...data];
                let index = newData.findIndex((item) => item.id === campaign.id);
                newData[index].adsets = response;

                setData(newData);
            }
        }).finally(() => {
            setLoadingCampaigns(loadingCampaigns.filter((item) => item !== campaign.id));
        });
    }


    return (
        <>
            <div className="d-flex flex-row justify-content-start m-block-gap">
                <Link to={'/p/'+params.projectId} className="btn btn-light-primary text-primary">Back to the project</Link>
            </div>


            {(data && data.length || loading) ? (
                <div className="table-responsive rounded-2">
                    <table className="table border customize-table mb-0 align-middle">
                        <thead className="text-dark fs-4">
                        <tr>
                            <th>
                                <h6 className={'fs-4 fw-semibold mb-0'}>
                                    Campaign
                                </h6>
                            </th>
                            <th style={{width: '300px'}}>
                                <h6 className={'fs-4 fw-semibold mb-0'}>
                                    Structure
                                </h6>
                            </th>
                            <th style={{width: '80px'}}></th>
                            <th style={{width: '160px'}}><h6 className="fs-4 fw-semibold mb-0"></h6></th>
                        </tr>
                        </thead>
                        <tbody>
                        {data && data.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <div className="d-flex gap-1 flex-column">
                                            <Link to={'/p/' + params.projectId + '/' + item.id} className="d-block">{item.name}{!item.is_active ? (
                                                <span className="text-danger ms-3 fw-bold">— inactive</span>) : ('')}</Link>
                                            <div className="text-muted fs-3">{item.synced_at ? 'Last Synced: ' + Grades.getDateFormatted(item.synced_at) : 'Never Synced'}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                Adsets: {item.adsets && item.adsets.stats && item.adsets.stats.adsetsCount ? item.adsets.stats.adsetsCount : 0}<br/>
                                                Ads: {item.adsets && item.adsets.stats && item.adsets.stats.adsCount ? item.adsets.stats.adsCount : 0}
                                            </div>

                                            <ButtonWithLoading
                                                noOriginalClassName={true}
                                                className="btn btn-circle btn-light-primary text-primary"
                                                noOriginalSpinnerClassName={true}
                                                spinnerClassName="spinner-border spinner-border-sm text-primary"
                                                noOriginalIconClassName={true}
                                                iconClassName="ti ti-refresh fs-4 text-primary"
                                                label=""
                                                onClick={() => {
                                                    onCampaignRefresh(item)
                                                }}
                                                loading={loadingCampaigns.includes(item.id)}
                                            />
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        {item.is_generic ? (<i className="ti ti-bolt fs-4 text-success"></i>) : (
                                            <i className="ti ti-bolt-off fs-4 text-muted"></i>)}
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-end gap-3">
                                            <button className="btn btn-circle btn-light-warning text-warning" onClick={() => {
                                                onActiveToggleClick(item)
                                            }}>
                                                <i className="ti ti-archive fs-4"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="text-center fs-2 mt-4">
                    No campaigns found.
                </div>
            )}
        </>
    );
}