import React, {useEffect} from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useNotifications} from "../../providers/NotificationsProvider";
import {useApi} from "../../providers/ApiProvider";
import ProjectsModel from "../../models/ProjectsModel";
import ProjectsSearchForm from "./ProjectsSearchForm";
import {Link} from "react-router-dom";
import SimpleModal from "../ui/SimpleModal";
import {useMeta} from "../../providers/MetaProvider";
import Grades from "../../helpers/Grades";

export default function ProjectsList(props) {
    const metaContext = useMeta();
    const breadcrumbs = useBreadcrumbs();
    const notifications = useNotifications();

    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);

    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = React.useState(false);
    const [shareWith, setShareWith] = React.useState(null);
    const [showShareModal, setShowShareModal] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [filter, setFilter] = React.useState({
        searchText: null,
        sorting: {field: 'synced_at', direction: 'desc'},
        archived: 0
    });
    const [loading, setLoading] = React.useState(true);


    const loadData = () => {
        setLoading(true);

        projectsModel.list(filter.searchText, filter.sorting, filter.archived, uploadErrorHandler).then((response) => {
            if (response !== false) {
                setData(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const uploadErrorHandler = (response) => {
        if (response.data && response.data[0].error) {
            notifications.notify('Loading error', 'error');
        }
    }


    useEffect(() => {
        metaContext.update({
            title: 'My Projects'
        });

        let newFilter = {...filter};
        let urlParams = new URLSearchParams(window.location.search);
        let search = urlParams.get('filter');
        if (search) {
            newFilter.searchText = search;
        }

        let archived = urlParams.get('archived');
        if (archived) {
            newFilter.active = 1;
        }

        let sort = urlParams.get('sort');
        let direction = urlParams.get('direction');
        if (sort && direction) {
            newFilter.sorting = {field: sort, direction: direction};
        }

        if (!search && !archived && !sort && !direction) {
            loadData();
        } else {
            setFilter(newFilter);
        }
    }, []);


    useEffect(() => {
        loadData();
        updateUrl();
    }, [filter]);


    const updateUrl = () => {
        let url = '/p';
        let query = {};
        if (filter.searchText) {
            query.filter = filter.searchText;
        }
        if (filter.active) {
            query.active = 1;
        }
        if (filter.sorting.field && filter.sorting.direction) {
            query.sort = filter.sorting.field;
            query.direction = filter.sorting.direction;
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }
        window.history.pushState({}, '', url);
    }


    const onSearch = (searchText) => {
        let newFilter = {...filter};
        newFilter.searchText = searchText;
        setFilter(newFilter);
    }


    const onArchivedToggle = () => {
        let newFilter = {...filter};
        newFilter.active = (newFilter.active === null || newFilter.active === 0) ? 1 : 0;
        setFilter(newFilter);
    }


    const onSort = (e) => {
        let newFilter = {...filter};
        let sortField = e.target.getAttribute('data-sort');
        let sortDirection = 'asc';

        if (e.target.classList.contains('asc')) {
            e.target.classList.remove('asc');
            e.target.classList.add('desc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-ascending');
                e.target.querySelector('i.ti').classList.add('ti-sort-descending');
            }

            sortDirection = 'desc';
        } else {
            e.target.classList.remove('desc');
            e.target.classList.add('asc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-descending');
                e.target.querySelector('i.ti').classList.add('ti-sort-ascending');
            }
        }

        newFilter.sorting = {field: sortField, direction: sortDirection};
        setFilter(newFilter);
    }


    const onDeleteClick = (projectId) => {
        setLoading(true);

        projectsModel.delete(projectId, uploadErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('Project deleted', 'success');
                setShowDeleteConfirmModal(false);
                loadData();
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onArchiveClick = (project) => {
        projectsModel.update(project.id, {is_active:  project.is_active ? 0 : 1}, uploadErrorHandler).then((response) => {
            if (response !== false) {
                notifications.notify('Project updated', 'success');
            }
        }).finally(() => {
            loadData();
        });
    }


    return (
        <>
            <SimpleCardContainer
                title={'My Projects'}
                loading={loading}
            >
                <div className={'pt-2 pb-4'}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="flex-grow-1 gap-3">
                            <Link to="/p/new" className="btn btn-light-success text-success">
                                <i className="ti ti-plus fs-4 me-2"></i>
                                Add a New Project
                            </Link>
                        </div>

                        <ProjectsSearchForm onSearch={onSearch}/>
                    </div>
                </div>

                {(data && data.length || loading) ? (
                    <div className="table-responsive rounded-2">
                        <table className="table border customize-table mb-0 align-middle">
                            <thead className="text-dark fs-4">
                            <tr>
                                <th>
                                    <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable ' + ((filter.sorting.field === 'title') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                        data-sort="title" onClick={onSort}>
                                        Project Title
                                        {(filter.sorting.field === 'title') && (filter.sorting.direction === 'asc') && (
                                            <i className={'ti ti-sort-ascending fs-5'}></i>
                                        )}
                                        {(filter.sorting.field === 'title') && (filter.sorting.direction === 'desc') && (
                                            <i className={'ti ti-sort-descending fs-5'}></i>
                                        )}
                                    </h6>
                                </th>
                                <th style={{width: '200px'}}>
                                    <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable ' + ((filter.sorting.field === 'synced_at') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                        data-sort="synced_at" onClick={onSort}>
                                        Last Synced
                                        {(filter.sorting.field === 'synced_at') && (filter.sorting.direction === 'asc') && (
                                            <i className={'ti ti-sort-ascending fs-5'}></i>
                                        )}
                                        {(filter.sorting.field === 'synced_at') && (filter.sorting.direction === 'desc') && (
                                            <i className={'ti ti-sort-descending fs-5'}></i>
                                        )}
                                    </h6>
                                </th>
                                <th style={{width: '160px'}}><h6 className="fs-4 fw-semibold mb-0"></h6></th>
                            </tr>
                            </thead>
                            <tbody>
                            {data && data.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div className="d-flex gap-1 flex-column">
                                                <Link to={'/p/' + item.id} className="d-block">{item.title}{!item.is_active ? (
                                                    <span className="text-danger ms-3 fw-bold">— archived</span>) : ('')}</Link>
                                            </div>
                                        </td>
                                        <td>{Grades.getDateFormatted(item.synced_at)}</td>
                                        <td>
                                            <div className="d-flex justify-content-end gap-3">
                                                <button className="btn btn-circle btn-light-warning text-warning" onClick={() => {
                                                    onArchiveClick(item)
                                                }}>
                                                    <i className="ti ti-archive fs-4"></i>
                                                </button>
                                                <button className="btn btn-circle btn-light-danger text-danger" onClick={() => {
                                                    setShowDeleteConfirmModal(item.id)
                                                }}>
                                                    <i className="ti ti-trash fs-4"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="text-center fs-2 mt-4">
                        No projects found.
                    </div>
                )}

                <div className="d-flex w-100 justify-content-end mt-3">
                    <button className="btn btn-light-primary text-primary" onClick={onArchivedToggle}>
                        {filter.archived ? 'Show Active' : 'Show Archived'}
                    </button>
                </div>
            </SimpleCardContainer>

            <SimpleModal
                visible={showDeleteConfirmModal}
                onClose={() => {
                    setShowDeleteConfirmModal(false)
                }}
                modalContentClassName={'modal-filled bg-light-danger'}
                headerTitle={null}
            >
                <div className="text-center pt-3">
                    <i className="ti ti-hexagon-letter-x fs-7 text-danger"></i>
                    <h4 className="mt-2 text-danger">Confirm Delete</h4>
                    <p className="mt-3">Please confirm that you want to delete this project.</p>

                    <div className="d-flex flex-row gap-3 my-2">
                        <button
                            type="button"
                            className="btn btn-light text-danger"
                            onClick={() => {
                                setShowDeleteConfirmModal(false)
                            }}
                        >
                            Cancel
                        </button>

                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                                onDeleteClick(showDeleteConfirmModal)
                            }}
                        >
                            Confirm Delete
                        </button>
                    </div>
                </div>
            </SimpleModal>
        </>
    );
}