import React from 'react';

import SimpleCardContainer from "../../ui/SimpleCardContainer";
import FacebookAuthContainer from "../FacebookAuthContainer";

export default function ProjectCreate({}) {
    return (<div className="d-flex flex-column align-items-center">
        <div className="align-items-start d-flex flex-column" style={{maxWidth: '500px'}}>
            <SimpleCardContainer
                title={'Connect an Ad Account'}
                progress={0.1}
            >
                <div className="fs-3 mb-3">Select a Business Manager and grant permissions on a new Ad Account:</div>

                <FacebookAuthContainer className="w-100" />
            </SimpleCardContainer>
        </div>
    </div>);
}
