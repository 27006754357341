import React, {useEffect} from 'react';

import ButtonWithLoading from "../layout/ui/ButtonWithLoading";
import FormTextField from "../layout/ui/FormTextField";
import FormTextArea from "../layout/ui/FormTextArea";
import FormCheckbox from "../layout/ui/FormCheckbox";
import FormSingleImage from "../layout/ui/FormSingleImage";

export default function ProjectsForm({formData, onSubmit, errors, loading, isNewRecord, className = ''}) {
    const [data, setData] = React.useState({});


    useEffect(() => {
        setData(formData);
    }, [formData]);


    const updateField = (field, value) => {
        let newData = {...data};
        newData[field] = value;

        setData(newData);
    }


    const onSubmitClick = (e) => {
        e.preventDefault();

        if (!data.hasOwnProperty('is_active')) {
            data.is_active = 0;
        }

        onSubmit(data);
    }


    return (
        <form encType="multipart/form-data" className={className}>
            <div className={'row g-4'}>
                <div className={'col-md-4'}>
                    <FormTextField
                        label={'AdAccount ID'}
                        name={'adaccount_id'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('adaccount_id', value)
                        }}
                        value={data.adaccount_id}
                        required={true}
                        disabled={!isNewRecord}
                    />
                </div>
                <div className={'col-md-8'}>
                    <FormTextField
                        label={'Title'}
                        name={'title'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('title', value)
                        }}
                        value={data.title}
                        required={true}
                    />
                </div>
                <div className={'col-md-12'}>
                    <FormCheckbox
                        label={'Active project'}
                        name={'is_active'}
                        errors={errors}
                        onChange={(value) => {
                            updateField('is_active', value)
                        }}
                        value={data.is_active}
                        groupClassName={''}
                    />
                </div>
            </div>

            <div className={'form-group mt-4'}>
                <ButtonWithLoading
                    className={'btn-primary'}
                    label={'Save changes'}
                    loading={loading}
                    noOriginalIconClassName={true}
                    iconClassName={'ti fs-5 me-2 ti-device-floppy'}
                    onClick={onSubmitClick}
                />
            </div>
        </form>
    );
}
