import React from "react";

export default function FormSelect({label, name, errors, options = [], onChange, value, multiple = false, placeholder = '', groupClassName = '', className = '', required = false, disabled = false, readOnly = false, hint = ''}) {

    const onFieldChange = (e) => {
        onChange(e.target.value);
    }

    return (
        <div className={'form-group ' + groupClassName}>
            <label className="form-label fw-semibold mb-2" htmlFor={'cf-' + name}>{label}</label>

            <select className={'form-select ' + className} id={'cf-' + name} value={value ? value : ''} onChange={onFieldChange} multiple={multiple} required={required} disabled={disabled} readOnly={readOnly}>
                {placeholder ? <option value="" disabled={true}>{placeholder}</option> : ''}
                {options.map((item, index) => {
                    return <option value={item.value} key={index}>{item.label}</option>
                })}
            </select>

            <div className="d-flex justify-content-between mt-1">
                {errors && errors[name] && <div className="form-text text-danger">{errors[name].join(' ')}</div>}
                <div className={'flex-grow-1 text-end fs-2'}>{hint}</div>
            </div>
        </div>
    )
}