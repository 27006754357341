import BaseCrudModel from "../BaseCrudModel";

/**
 * Users model
 *
 * @param {ApiHelper} api
 */
export default class UsersModel extends BaseCrudModel {
    baseUrl = '/users';

    constructor(api) {
        super(api);
    }


    async profile(errorHandler) {
        const url = this.baseUrl+'/profile';

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async updateAvatar(file, errorHandler) {
        const url = this.baseUrl+'/update-avatar';

        const response = await this.api.postRequest(url, {file: file});

        return this.processResponse(response, errorHandler);
    }


    async syncAvatarWithFacebook(errorHandler) {
        const url = this.baseUrl+'/update-avatar';

        const response = await this.api.postRequest(url, {sync_with_facebook: true});

        return this.processResponse(response, errorHandler);
    }


    async updatePassword(currentPassword, newPassword, errorHandler) {
        const url = this.baseUrl+'/change-password';

        const response = await this.api.postRequest(url, {old_password: currentPassword, new_password: newPassword});

        return this.processResponse(response, errorHandler);
    }
}