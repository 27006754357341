import React, {useEffect} from 'react';

import {Outlet, useNavigate} from "react-router-dom";
import ProfilePhotoCard from "./ProfilePhotoCard";
import PersonalInfoCard from "./PersonalInfoCard";
import ChangePasswordCard from "./ChangePasswordCard";
import ConnectedAccounts from "./ConnectedAccounts";

export default function AccountSettings({}) {
    const navigate = useNavigate();

    useEffect(() => {
    }, []);


    return (
        <div className="row">
            <div className="col-lg-4 d-flex align-items-stretch">
                <ProfilePhotoCard />
            </div>
            <div className="col-lg-4 d-flex align-items-stretch">
                {/*<PersonalInfoCard />
                <ChangePasswordCard />*/}
            </div>
            <div className="col-lg-4 d-flex align-items-stretch">
                {/*<ConnectedAccounts />*/}
            </div>
        </div>
    );
}