import React, {useEffect} from 'react';

import {useApi} from "../../../providers/ApiProvider";
import {Link} from "react-router-dom";
import {useNotifications} from "../../../providers/NotificationsProvider";
import ProjectsModel from "../../../models/ProjectsModel";
import {useAuth} from "../../../providers/AuthProvider";
import SimpleCardContainer from "../../ui/SimpleCardContainer";
import ProjectCreateForm from "./ProjectCreateForm";

export default function ProjectCreateAccount({}) {
    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);
    const notifications = useNotifications();
    const {user} = useAuth();

    const [data, setData] = React.useState({});
    const [accessToken, setAccessToken] = React.useState('');
    const [loading, setLoading] = React.useState('');
    const [error, setError] = React.useState('');
    const [progress, setProgress] = React.useState(0.1);


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        setLoading('exchangingCode');
        if (code) {
            projectsModel.exchangeFacebookCode(code).then((response) => {
                if (response.access_token) {
                    setAccessToken(response.access_token);
                    setLoading('fetchingPages');
                    projectsModel.fetchPages(response.access_token).then((response) => {
                        if (response) {
                            setData(response);
                            setProgress(0.2);
                        }
                    }).finally(() => {
                        setLoading('');
                    });
                } else {
                    setLoading('noCode');

                    if (response.error && response.error.message) {
                        setError(response.error.message);
                    } else {
                        setError('Undefined error');
                    }
                }
            });
        } else {
            setLoading('noCode');
            setError('No code provided');
        }
    }, []);


    const pushProgress = () => {
        setProgress(0.3);
    }


    return (<div className="d-flex flex-column align-items-center">
        <div className="align-items-start d-flex flex-column w-100" style={{maxWidth: '500px'}}>
            <Link to="/p/new" className="btn btn-light-primary text-primary d-flex align-items-center mb-3 w-auto"><i className="ti ti-arrow-left fs-4 me-2"></i>Select another ad account</Link>

            <SimpleCardContainer
                title={'Select an Ad Account'}
                loading={loading === 'submitted'}
                progress={progress}
            >
                {loading === 'exchangingCode' && (
                    <div>Exchanging code...</div>
                )}
                {loading === 'fetchingPages' && (
                    <div>Fetching pages...</div>
                )}
                {loading === 'noCode' && (
                    <div className="text-danger">{error}</div>
                )}
                {(!!Object.keys(data).length) && (
                    <div className="">
                        <ProjectCreateForm
                            adAccounts={data}
                            accessToken={accessToken}
                            onSubmitInitialized={pushProgress}
                        />
                    </div>
                )}
            </SimpleCardContainer>
        </div>
    </div>);
}
