import React, {useEffect} from 'react';

import {Link} from "react-router-dom";
import ButtonWithLoading from "../../layout/ui/ButtonWithLoading";
import Grades from "../../../helpers/Grades";

export default function ProjectTitle({loading = [], project, onRefresh, onExport}) {


    return project && (
        <div className="px-3 m-block-gap">
            <div className="d-flex align-items-center pb-2">
                <div className="w-100 d-flex align-items-start">
                    <div className="">
                        <h5 className="fw-semibold fs-5 mb-1 position-relative">
                            {project.title}
                        </h5>
                        <p className="fs-3 mb-0 text-muted">{project.synced_at ? 'last synced '+Grades.getDateFormatted(project.synced_at): 'not synced'}</p>
                    </div>
                    <div className="d-flex gap-2 ms-3">
                        <Link to={'/'+['p', project.id, 'edit'].join('/')} className="bg-light rounded py-1 px-8 d-inline-flex align-items-center text-decoration-none"
                           title="Edit">
                            <i className="ti ti-pencil fs-3 text-dark"></i>
                        </Link>
                        <ButtonWithLoading
                            noOriginalClassName={true}
                            className="btn btn-light-primary d-inline-flex align-items-center px-8 py-1 rounded"
                            noOriginalSpinnerClassName={true}
                            spinnerClassName="spinner-border spinner-border-xs text-primary"
                            noOriginalIconClassName={true}
                            iconClassName="ti ti-refresh fs-3 text-primary"
                            label=""
                            onClick={onRefresh}
                            loading={loading.includes('refresh')}
                        />
                        <ButtonWithLoading
                            noOriginalClassName={true}
                            className="btn btn-light d-inline-flex align-items-center px-8 py-1 rounded"
                            noOriginalSpinnerClassName={true}
                            spinnerClassName="spinner-border spinner-border-xs text-dark"
                            noOriginalIconClassName={true}
                            iconClassName="ti ti-download fs-3 text-dark"
                            label=""
                            onClick={onExport}
                            loading={loading.includes('export')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
