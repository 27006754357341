import React, {useState} from 'react';

const GoogleAuthProcessing = (props) => {
    document.title = 'Authenticating | SpryntAI';

    return (
        <>
            <div className="text-center">
                <h4 className="mb-0">Authenticating...</h4>
            </div>
        </>
    );
}

export default GoogleAuthProcessing;
