import React, {useEffect} from "react";
import FacebookLogin from '@greatsumini/react-facebook-login';
import {useOutletContext} from "react-router-dom";


export default function FacebookLoginContainer({}) {

    const parentContext = useOutletContext();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            parentContext.onFacebookAuth(code);
        }
    }, []);


    return (
        <div className="d-flex align-items-center justify-content-center">
            <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                useRedirect={true}
                initParams={{
                    version: process.env.REACT_APP_FACEBOOK_GRAPH_VERSION,
                    xfbml: true,
                }}
                dialogParams={{
                    config_id: process.env.REACT_APP_CONFIGURATION_REGULAR_USERS,
                    redirect_uri: window.location.origin + process.env.REACT_APP_FACEBOOK_REDIRECT_URI,
                    //scope: process.env.REACT_APP_FACEBOOK_SCOPES
                }}
                loginOptions={{
                    return_scopes: true,
                }}

                className={''}
                style={{
                    backgroundColor: '#4267b2',
                    color: '#fff',
                    fontSize: '16px',
                    padding: '12px 24px',
                    border: 'none',
                    borderRadius: '4px',
                }}
            />
        </div>
    );
}