import React from "react";
import CardLoading from "./CardLoading";

function SimpleCardContainer({loading, title, titleRight, children, className = '', cardBodyClassName = 'p-4', progress = 0}) {
    return (
        <div className={'card w-100 position-relative overflow-hidden '+className+(loading ? ' loading' : '')}>
            {title && (
            <div className="px-4 py-3 border-bottom d-flex align-items-center justify-content-between">
                <h5 className="align-items-center card-title d-flex fw-semibold gap-2 lh-sm mb-0">{title}</h5>

                {titleRight && (
                    <div className="d-flex align-items-center">
                        {titleRight}
                    </div>
                )}
            </div>
            )}
            <div className={'card-body '+cardBodyClassName}>
                {children}
            </div>

            {progress > 0 && (
                <div className="progress bg-light">
                    <div className="progress-bar progress-bar-striped bg-primary progress-bar-animated" style={{width: (progress*100)+'%', height: '6px'}} role="progressbar">
                        <span className="sr-only">{progress*100}% Complete</span>
                    </div>
                </div>
            )}

            <CardLoading loading={loading}/>
        </div>
    )
}

export default SimpleCardContainer;